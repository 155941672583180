import React from "react";
import "./styles.css";
import "../dapp.css";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import imgBalance from "../../../assets/images/logo/grey.png";
import imgMatic from "../../../assets/images/matic.png";
import imgETH from "../../../assets/images/ethereum.webp";

const DappMarket = ({
  isConnected,
  getSigner,
  provider,
  signerAddress,
  chains,
  setChainSelected,
  chainSelected,
}) => {
  var displayAddress = `${signerAddress?.substring(0, 10)}...`;
  return (
    <>
      <Header
        isConnected={isConnected}
        displayAddress={displayAddress}
        getSigner={getSigner}
        provider={provider}
        chains={chains}
        chainSelected={chainSelected}
        setChainSelected={setChainSelected}
      />
      <section className="dapp-section">
        <div className="pp-container">
          <div className="pt-4 text-2xl font-semibold text-primary md:pt-0 bl-0101">
            Balance Markets
          </div>

          <div>
            <div className="flex flex-col flex-wrap md:flex-row md:items-center">
              <div className="transition rounded-lg flex border border-water-500 bg-transparent cursor-text focus-within:ring-water-300 focus-within:border-sky w-full md:w-[300px] space-between">
                <input
                  className="w-full md:w-[300px] bg-transparent text-white placeholder:text-water-500 outline-none border-none focus:border-none focus:placeholder:text-water-300 focus:ring-0 block w-full px-3 py-2 text-base transition rounded-lg h-9 bl-0102"
                  id="pp-searchinput-4799"
                  type="text"
                  placeholder="Search"
                />
                <label
                  for="pp-searchinput-4799"
                  className="flex items-center pr-3 transition cursor-text text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    className="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </label>
              </div>
              <div className="flex-grow hidden md:block"></div>
              <div className="flex justify-end order-12 w-full pt-4 pb-8 md:pt-4 md:pb-4 lg:pt-0 lg:pb-0 lg:pr-4 lg:order-none lg:w-auto">
                <div
                  className="flex items-center gap-x-1.5"
                  data-headlessui-state=""
                >
                  <label
                    id="headlessui-listbox-label-89"
                    data-headlessui-state=""
                    className=""
                  >
                    Sort by:
                  </label>
                  <div className="relative">
                    <button
                      id="headlessui-listbox-button-90"
                      type="button"
                      aria-haspopup="listbox"
                      aria-expanded="false"
                      data-headlessui-state=""
                      className="relative py-1 pl-0.5 pr-6 transition-colors text-left text-primary border-2 border-transparent focus:outline-none group focus:border-b-grass hover:border-b-grass border-b-white"
                      aria-labelledby="headlessui-listbox-label-89 headlessui-listbox-button-90"
                    >
                      <span className="transition group-hover:text-grass group-focus:text-grass bl-0111">
                        Starred
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1">
                        <svg
                          width="14"
                          height="8"
                          viewBox="0 0 14 8"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3.5 w-3.5 relative top-px transition group-focus:text-grass group-hover:text-grass"
                          aria-hidden="true"
                        >
                          <path d="M7.00023 7.71309L13.0102 1.70309L11.5972 0.288086L7.00023 4.88809L2.40423 0.288086L0.990234 1.70209L7.00023 7.71309Z"></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex mt-6 md:mt-0">
                <div className="flex-grow block md:hidden"></div>
              </div>
              <div
                role="tablist"
                aria-orientation="horizontal"
                className="flex pp-tab-items hidden ml-4 children:p-1 md:flex hidden ml-4 children:p-1 md:flex"
                modelvalue="1"
                variant="text"
                data-test="layout-selector"
              ></div>
            </div>
            <div className="mt-4">
              <div>
                <span
                  className="bl-0104"
                  id="headlessui-tabs-panel-96"
                  role="tabpanel"
                  tabindex="-1"
                  aria-labelledby="headlessui-tabs-tab-92"
                ></span>
                <div
                  id="headlessui-tabs-panel-97"
                  role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-93"
                  tabindex="0"
                  data-headlessui-state="selected"
                >
                  <div className="pt-6 lg:pt-10">
                    <div>
                      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  sFRAX
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Frax
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/prod-pendle-bucket-a/images/uploads/719dea4a-a775-4c22-84fa-2b6d3d5c42fb.svg"
                              alt="sFRAX"
                              className="bl-1002 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    27 Mar 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="27/03/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">107 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            5.386
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 0.99967462"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $1.00
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="5.548341904465537"
                                        className="whitespace-nowrap"
                                      >
                                        5.551
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              19.02
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.015745252045613427"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $0.02
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="5.548341904465537"
                                              className="whitespace-nowrap"
                                            >
                                              5.551
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.9839293679543866"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $0.98
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  crvUSD
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Silo
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/prod-pendle-bucket-a/images/uploads/fe707d06-938d-4f44-b578-f221d6a646f3.svg"
                              alt="crvUSD"
                              className="1003 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    27 Mar 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="27/03/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">107 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            10.72
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 1.0001940093600001"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $1.00
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="8.26431140560353"
                                        className="whitespace-nowrap"
                                      >
                                        8.292
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              20.33
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.0230796514425819"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $0.02
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="8.26431140560353"
                                              className="whitespace-nowrap"
                                            >
                                              8.292
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.9771143579174182"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $0.98
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  sDAI
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Spark{" "}
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/stage-pendle-bucket-a/images/uploads/60fa4994-a8ff-4340-bd3e-ec37c40dff57.svg"
                              alt="sDAI"
                              className="bl-1004 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    25 Sep 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="25/09/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">289 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            5.09
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 0.9998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $1.00
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="4.28551351832589"
                                        className="whitespace-nowrap"
                                      >
                                        4.3
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              21.84
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.032706938668512625"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $0.03
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="4.28551351832589"
                                              className="whitespace-nowrap"
                                            >
                                              4.3
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.9670930613314874"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $0.97
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  sDAI
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Spark
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/prod-pendle-bucket-a/images/uploads/103b7494-4c24-405b-b738-d1e9d512e905.svg"
                              alt="sDAI"
                              className="bl-1005 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    27 Mar 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="27/03/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">107 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            5.07
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 0.9998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $1.00
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="4.804761457522111"
                                        className="whitespace-nowrap"
                                      >
                                        4.856
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              7.995
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.01369987573036596"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $0.01
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="4.804761457522111"
                                              className="whitespace-nowrap"
                                            >
                                              4.856
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.9861001242696341"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $0.99
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  fUSDC
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Flux
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/stage-pendle-bucket-a/images/uploads/1dfb3910-475d-4f22-9180-22a9c6e54674.svg"
                              alt="fUSDC"
                              className="bl-1006 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    25 Dec 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="25/12/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">380 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            4.61
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 1.000036"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $1.00
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="3.739673086781137"
                                        className="whitespace-nowrap"
                                      >
                                        3.86
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              20.86
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.03753335867959465"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $0.04
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="3.739673086781137"
                                              className="whitespace-nowrap"
                                            >
                                              3.86
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.9625026413204053"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $0.96
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  eUSD
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Lybra
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/prod-pendle-bucket-a/images/uploads/fb1c78d2-ebd6-43f5-909a-4d47f10ed7a5.svg"
                              alt="eUSD"
                              className="bl-1007 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    25 Dec 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="25/12/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">380 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            7.102
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 1.0283116862754769"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $1.03
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="4.650401343866539"
                                        className="whitespace-nowrap"
                                      >
                                        4.65
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              51.87
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.045770293904975494"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $0.05
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="4.650401343866539"
                                              className="whitespace-nowrap"
                                            >
                                              4.65
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 0.9436911143029693"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $0.94
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  swETH
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Swell
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/stage-pendle-bucket-a/images/uploads/05f1f0a7-2eed-439f-bce4-aa342eb754e4.png"
                              alt="swETH"
                              className="bl-1008 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    25 Dec 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="25/12/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">380 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            3.358
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 2205.3998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $2,205.40
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="3.0481054165608867"
                                        className="whitespace-nowrap"
                                      >
                                        3.074
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              9.60
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 67.92851596994603"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $67.93
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="3.0481054165608867"
                                              className="whitespace-nowrap"
                                            >
                                              3.074
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 2137.471284030054"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $2,137.47
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  swETH
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Swell
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/prod-pendle-bucket-a/images/uploads/292bc0d5-5bf7-47b0-899b-a14829668fbe.png"
                              alt="swETH"
                              className="bl-1009 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    26 Jun 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="26/06/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">198 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            3.359
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 2205.3998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $2,205.40
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="3.1802647320230415"
                                        className="whitespace-nowrap"
                                      >
                                        3.25
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              7.15
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 37.19634935021795"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $37.20
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="3.1802647320230415"
                                              className="whitespace-nowrap"
                                            >
                                              3.25
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 2168.203450649782"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $2,168.20
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  stETH
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Lido
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/stage-pendle-bucket-a/images/uploads/102fb7dd-12c8-4a3b-93be-a8fb4dd95ff2.svg"
                              alt="stETH"
                              className="bl-1010 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    29 Dec 2027
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="29/12/2027, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">1479 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            4.01
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 2205.3998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $2,205.40
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="3.799222291251847"
                                        className="whitespace-nowrap"
                                      >
                                        3.82
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              4.31
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 309.14975703800957"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $309.15
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="3.799222291251847"
                                              className="whitespace-nowrap"
                                            >
                                              3.82
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 1894.9586655149412"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $1,894.96
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  stETH
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Lido
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/stage-pendle-bucket-a/images/uploads/4d0f1d51-9ae3-45da-a2d4-e9ea79b05d2d.svg"
                              alt="stETH"
                              className="bl-1011 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    24 Dec 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="24/12/2025, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">744 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            4.05
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 2205.3998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $2,205.40
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="3.745380644689478"
                                        className="whitespace-nowrap"
                                      >
                                        3.80
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              5.32
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 159.22117704707435"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $159.22
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="3.745380644689478"
                                              className="whitespace-nowrap"
                                            >
                                              3.80
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 2044.8872455058763"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $2,044.89
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  stETH
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Lido
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/prod-pendle-bucket-a/images/uploads/e9334d95-fafe-47e4-a552-be4027a96edd.svg"
                              alt="stETH"
                              className="bl-1012 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    25 Dec 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="25/12/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">380 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            4.004
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 2205.3998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $2,205.40
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="4.0395550500362365"
                                        className="whitespace-nowrap"
                                      >
                                        4.08
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              -0.50
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 89.09535837974953"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $89.10
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="4.0395550500362365"
                                              className="whitespace-nowrap"
                                            >
                                              4.08
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 2115.013064173201"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $2,115.01
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  OETH
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Origin
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/prod-pendle-bucket-a/images/uploads/abc43358-0eb0-4734-ad65-641b77f82c40.svg"
                              alt="OETH"
                              className="bl-1013 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    25 Dec 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="25/12/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">380 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            5.70
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 2205.3998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $2,205.40
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="4.338046313550814"
                                        className="whitespace-nowrap"
                                      >
                                        4.4
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              30.51
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 94.48645665990753"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $94.49
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="4.338046313550814"
                                              className="whitespace-nowrap"
                                            >
                                              4.4
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 2088.5246935338264"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $2,088.52
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  ETHx
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Stader
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/prod-pendle-bucket-a/images/uploads/f3b4c4f0-b5ef-4d61-8686-616a8f2f2ab1.svg"
                              alt="ETHx"
                              className="bl-1014 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    25 Dec 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="25/12/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">380 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            3.313
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 2228.0697223786588"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $2,228.07
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="2.984175798652977"
                                        className="whitespace-nowrap"
                                      >
                                        3
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              8.402
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 66.54571503141769"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $66.55
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="2.984175798652977"
                                              className="whitespace-nowrap"
                                            >
                                              3
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 2138.8540849685824"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $2,138.85
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  sfrxETH
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Frax
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/prod-pendle-bucket-a/images/uploads/6fe6a0d7-1196-4916-9d0b-aa3a878dcc8d.svg"
                              alt="sfrxETH"
                              className="bl-1015 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    25 Dec 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="25/12/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">380 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            4.107
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 2205.3998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $2,205.40
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="4.292363912276964"
                                        className="whitespace-nowrap"
                                      >
                                        4.31
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              -5.25
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 95.5813805108916"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $95.58
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="4.292363912276964"
                                              className="whitespace-nowrap"
                                            >
                                              4.31
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 2135.239856166146"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $2,135.24
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  ankrETH-wstETH
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Aura
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/stage-pendle-bucket-a/images/uploads/3a378b1a-0930-48b4-80bf-d5ff38c5b7e9.svg"
                              alt="ankrETH-wstETH"
                              className="bl-1017 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    26 Jun 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="26/06/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">198 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            4.625
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 2205.3998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $2,205.40
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="3.658587734713015"
                                        className="whitespace-nowrap"
                                      >
                                        3.7
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              50.80
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 42.40494853940944"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $42.40
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="3.658587734713015"
                                              className="whitespace-nowrap"
                                            >
                                              3.7
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 2150.93427620183"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $2,150.93
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          data-v-3b861854=""
                          className="overflow-hidden pp-card bg-water-200 bg-opacity-[0.07] text-water-300 overflow-hidden"
                        >
                          <div
                            data-v-3b861854=""
                            className="pp-card-title flex"
                            data-test="symbol"
                          >
                            <div data-v-3b861854="">
                              <div
                                data-v-3b861854=""
                                className="flex items-center"
                              >
                                <div
                                  data-v-3b861854=""
                                  className="text-xl font-bold text-primary text-neon-sky"
                                >
                                  StaFirETH-WETH
                                </div>
                                <span
                                  data-v-15b9e16a=""
                                  data-v-3b861854=""
                                  data-v-tippy=""
                                  className="ml-1.5"
                                >
                                  <a
                                    data-v-15b9e16a=""
                                    href="#"
                                    target="_blank"
                                    rel="noopener"
                                    className="inline-flex items-center rounded-[1px] gap-x-0.5 py-0.5 px-1.5 h-[21px] text-primary bg-water-200/20 hover:bg-water-200/[0.35] focus:bg-water-200/[0.35] transition-all"
                                  >
                                    <span data-v-15b9e16a="">
                                      <span
                                        title="0.09999999999998899"
                                        className="whitespace-nowrap"
                                      >
                                        0.1
                                      </span>
                                      %
                                    </span>
                                  </a>
                                </span>
                                <div
                                  data-v-3b861854=""
                                  className="ml-1.5 relative top-px"
                                >
                                  <button data-v-3b861854="" type="button">
                                    <svg
                                      className="text-water-600 hover:text-[#EFB54B] transition"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.89952 14.7419L4.58536 17.3408C4.39477 17.4621 4.19552 17.5141 3.98761 17.4968C3.7797 17.4794 3.59778 17.4101 3.44184 17.2888C3.28591 17.1676 3.16463 17.0158 3.078 16.8335C2.99137 16.6512 2.97404 16.4478 3.02602 16.2233L4.16953 11.3114L0.349162 8.0108C0.175903 7.85486 0.0677886 7.6771 0.0248202 7.4775C-0.0181481 7.27791 -0.00532696 7.08317 0.0632838 6.89327C0.132588 6.70269 0.236543 6.54675 0.375151 6.42547C0.513758 6.30419 0.704344 6.22623 0.946907 6.19157L5.98876 5.74976L7.93793 1.12373C8.02456 0.915823 8.15901 0.759889 8.34127 0.655934C8.52354 0.551978 8.70962 0.5 8.89952 0.5C9.0901 0.5 9.27618 0.551978 9.45776 0.655934C9.63933 0.759889 9.77378 0.915823 9.8611 1.12373L11.8103 5.74976L16.8521 6.19157C17.0947 6.22623 17.2853 6.30419 17.4239 6.42547C17.5625 6.54675 17.6664 6.70269 17.7357 6.89327C17.805 7.08386 17.8182 7.27895 17.7752 7.47854C17.7323 7.67814 17.6238 7.85556 17.4499 8.0108L13.6295 11.3114L14.773 16.2233C14.825 16.4485 14.8077 16.6523 14.721 16.8346C14.6344 17.0168 14.5131 17.1683 14.3572 17.2888C14.2013 17.4101 14.0193 17.4794 13.8114 17.4968C13.6035 17.5141 13.4043 17.4621 13.2137 17.3408L8.89952 14.7419Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div
                                data-v-3b861854=""
                                className="mt-0.5 text-base"
                              >
                                Aura
                              </div>
                            </div>
                            <div data-v-3b861854="" className="flex-grow"></div>
                            <img
                              data-v-3b861854=""
                              src="https://storage.googleapis.com/prod-pendle-bucket-a/images/uploads/2b7a986e-936d-4fee-9d61-7da9c4035539.svg"
                              alt="StaFirETH-WETH"
                              className="bl-1018 inline-block h-9 w-9 pp-market-glow"
                            />
                          </div>
                          <div
                            data-v-3b861854=""
                            className="pp-card-body text-base text-secondary"
                          >
                            <div data-v-3b861854="" className="mt-4 space-y-4">
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Maturity{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <span
                                    data-v-3b861854=""
                                    data-test="expiry"
                                    className="text-primary"
                                  >
                                    26 Jun 2025
                                  </span>
                                  <span
                                    data-v-3b861854=""
                                    title="26/06/2024, 21:00:00"
                                    data-test="expires-in"
                                    className="ml-1"
                                  >
                                    <span className="">198 days</span>
                                  </span>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Underlying APY{" "}
                                    </div>
                                  </span>
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-light"
                                    >
                                      {" "}
                                      Price{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="text-primary"
                                    >
                                      <span data-v-3b861854="" data-v-tippy="">
                                        <span
                                          data-v-3b861854=""
                                          data-test="underlying-apy"
                                        >
                                          <span className="whitespace-nowrap">
                                            6.910
                                          </span>
                                          %
                                        </span>
                                      </span>
                                    </div>
                                    <div data-v-3b861854="">
                                      <span
                                        data-v-3b861854=""
                                        title="USD 2205.3998"
                                        className="whitespace-nowrap"
                                        data-test="underlying-price"
                                      >
                                        $2,205.40
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="flex">
                                <div className="">
                                  <span data-v-3b861854="" data-v-tippy="">
                                    <div
                                      data-v-3b861854=""
                                      className="font-bold"
                                    >
                                      {" "}
                                      Implied APY{" "}
                                    </div>
                                  </span>
                                </div>
                                <div className="flex-grow ml-2"></div>
                                <div className="">
                                  <div
                                    data-v-3b861854=""
                                    className="text-right text-primary"
                                  >
                                    <span data-v-3b861854="" data-test="pt-apy">
                                      <span
                                        title="7.043253176778452"
                                        className="whitespace-nowrap"
                                      >
                                        7.12
                                      </span>
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div data-v-3b861854="" className="space-y-4">
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="flex w-full pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-sky hover:neon-sky focus:border-sky focus:neon-sky bg-sky bg-opacity-10 hover:bg-opacity-15 transition flex w-full"
                                  disabled="false"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body w-full"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-sky"
                                      >
                                        {" "}
                                        YT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Long Yield APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="yt-apy"
                                          >
                                            <span className="whitespace-nowrap">
                                              0.3490
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 80.07121698434318"
                                            className="whitespace-nowrap"
                                            data-test="yt-price"
                                          >
                                            $80.07
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                                <a
                                  data-v-3b861854=""
                                  href="#"
                                  className="pp-card text-water-300 bg-size-105 bg-center border border-transparent transition focus:outline-none hover:neon-sm focus:neon-sm hover:border-grass hover:neon-grass focus:border-grass focus:neon-grass bg-grass bg-opacity-10 hover:bg-opacity-15 transition"
                                >
                                  <div
                                    data-v-3b861854=""
                                    className="pp-card-body"
                                  >
                                    <div
                                      data-v-3b861854=""
                                      className="flex items-center"
                                    >
                                      <div
                                        data-v-3b861854=""
                                        className="font-bold text-md text-neon-grass"
                                      >
                                        {" "}
                                        PT{" "}
                                      </div>
                                      <div data-v-3b861854="" className="ml-5">
                                        <span
                                          data-v-3b861854=""
                                          data-v-tippy=""
                                        >
                                          <div
                                            data-v-3b861854=""
                                            className="font-bold"
                                          >
                                            {" "}
                                            Fixed APY{" "}
                                          </div>
                                        </span>
                                        <div data-v-3b861854="">Price</div>
                                      </div>
                                      <div
                                        data-v-3b861854=""
                                        className="flex-grow"
                                      ></div>
                                      <div
                                        data-v-3b861854=""
                                        className="text-right"
                                      >
                                        <div
                                          data-v-3b861854=""
                                          className="text-primary"
                                        >
                                          <span
                                            data-v-3b861854=""
                                            data-test="pt-apy"
                                          >
                                            <span
                                              title="7.043253176778452"
                                              className="whitespace-nowrap"
                                            >
                                              7.12
                                            </span>
                                            %
                                          </span>
                                        </div>
                                        <div data-v-3b861854="">
                                          <span
                                            data-v-3b861854=""
                                            title="USD 2125.498107390002"
                                            className="whitespace-nowrap"
                                            data-test="pt-price"
                                          >
                                            $2,125.50
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DappMarket;
