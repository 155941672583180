import React from "react";
import "./styles.css";

import logoGrey from "../../assets/images/logo/grey-with-name.png";

import iconTwiter from "../../assets/images/icons/x.png";
import iconDiscord from "../../assets/images/icons/discord.png";
import iconAnnouncement from "../../assets/images/icons/announcement.png";
import iconMedium from "../../assets/images/icons/medium.png";
import iconReddit from "../../assets/images/icons/reddit.png";
import iconTelegram from "../../assets/images/icons/telegram.png";

const Footer = ({ home }) => {
  const socialMedia = [
    { src: iconTwiter, href: "https://x.com/meteorswap?s=21" },
    { src: iconDiscord, href: "/" },
    { src: iconAnnouncement, href: "https://t.me/MeteorSwapOfficial" },
    { src: iconMedium, href: "/" },
    // { src: iconReddit, href: "#" },
    { src: iconTelegram, href: "https://t.me/MeteorSwapOfficial" },
  ];
  return (
    <footer className="site-footer">
      <div className="footer-container">
        <div className="footer-media">
          <a className="footer-logo" href="/">
            <img
              width="250"
              src={logoGrey}
              className="footer-logo"
              alt=""
              loading="lazy"
              style={{ width: "250px !important;" }}
            />
          </a>
          <ul className="social-media-container">
            {socialMedia.map((item, index) => (
              <li key={`${index}__socialmedia`}>
                <a className="social-button" target="_blank" href={item.href}>
                  <img src={item.src} alt="" />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-act">
          <div className="footer-list">
            <h3>Community</h3>{" "}
            <ul>
              <li>
                <a href="/" target="_blank">Discord</a>
              </li>
              <li>
                <a href="https://t.me/MeteorSwapOfficial" target="_blank">Telegram</a>
              </li>
              <li>
                <a href="https://x.com/meteorswap?s=21" target="_blank">Twitter</a>
              </li>
            </ul>
          </div>
          <div className="footer-list">
            <h3>Protocol</h3>{" "}
            <ul>
              <li>
                <a href="https://balance-network.gitbook.io/balance-network/" target="_blank">Docs</a>
              </li>
            </ul>
          </div>
        </div>
        {home && <a href="/swap"><button className="balance-button">LAUNCH APP</button></a>}
      </div>
    </footer>
  );
};

export default Footer;
